(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/widgets/views/index.js') >= 0) return;  svs.modules.push('/modules/widgets/views/index.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.widgets=_svs.widgets||{};
_svs.widgets.templates=_svs.widgets.templates||{};
svs.widgets.templates.index = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"widgetArea")||(depth0 && lookupProperty(depth0,"widgetArea"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"widgetArea","hash":{"name":"moduleMain"},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":32}}}));
},"useData":true});
Handlebars.partials['svs-widgets-index'] = svs.widgets.templates.index;
})(svs, Handlebars);


 })(window);